// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-jxnblk-src-templates-post-js": () => import("../node_modules/gatsby-theme-jxnblk/src/templates/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-jxnblk-src-templates-post-js" */),
  "component---node-modules-gatsby-theme-jxnblk-src-templates-posts-js": () => import("../node_modules/gatsby-theme-jxnblk/src/templates/posts.js" /* webpackChunkName: "component---node-modules-gatsby-theme-jxnblk-src-templates-posts-js" */),
  "component---node-modules-gatsby-theme-jxnblk-src-templates-project-js": () => import("../node_modules/gatsby-theme-jxnblk/src/templates/project.js" /* webpackChunkName: "component---node-modules-gatsby-theme-jxnblk-src-templates-project-js" */),
  "component---src-pages-modal-js": () => import("../src/pages/modal.js" /* webpackChunkName: "component---src-pages-modal-js" */),
  "component---src-pages-about-mdx": () => import("../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-readings-mdx": () => import("../src/pages/readings.mdx" /* webpackChunkName: "component---src-pages-readings-mdx" */),
  "component---src-pages-index-mdx": () => import("../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

